(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name events.controller:ListCtrl
   *
   * @description
   *
   */
  angular
    .module('events')
    .controller('ListCtrl', ListCtrl);

  function ListCtrl() {
    var vm = this;
    vm.ctrlName = 'ListCtrl';
  }
}());
